const en = {
  translation: {
    'Sign in credentials': 'Sign in credentials',
    'Sign in': 'Sign in',
    'Add Category': 'Add Category',
    Title: 'Title',
    Description: 'Description',
    Add: 'Add',
    Remove: 'Remove',
    'Add/Remove': 'Add/Remove',
    Success: 'Success',
    Danger: 'Danger',
    Categories: 'Categories',
    Image: 'Image',
    Header: 'Header',
    Loading: 'Loading',
    Error: 'Error',
    Edit: 'Edit',
    Delete: 'Delete',
    'Edit Category': 'Edit Category',
    Save: 'Save',
    'OrderID prefix': 'OrderID prefix',
    Order: 'Order',
    Email: 'Email',
    Password: 'Password',
    Enable: 'Enable',
    Disable: 'Disable',
    'Enable/Disable': 'Enable/Disable',
    Saving: 'Saving',
    Paypal: 'Paypal',
    'Client ID': 'Client ID',
    'Client Secret': 'Client Secret',
    Sandbox: 'Sandbox',
    'Publishable Key': 'Publishable Key',
    'Secret Key': 'Secret Key',
    'Delivery Charges': 'Delivery Charges',
    Price: 'Price',
    'Add Food': 'Add Food',
    Category: 'Category',
    Select: 'Select',
    Variations: 'Variations',
    Type: 'Type',
    'Food Image': 'Food Image',
    Foods: 'Foods',
    Actions: 'Actions',
    'Edit Food': 'Edit Food',
    OrderID: 'OrderID',
    Orders: 'Orders',
    Name: 'Name',
    Items: 'Items',
    Payment: 'Payment',
    Status: 'Status',
    Review: 'Review',
    'Mark as': 'Mark as',
    Users: 'Users',
    Phone: 'Phone',
    Address: 'Address',
    2019: '2019',
    'About Us': 'About Us',
    Blog: 'Blog',
    Welcome: 'Welcome',
    Logout: 'Logout',
    Dashboard: 'Dashboard',
    Configuration: 'Configuration',
    Login: 'Login',
    Food: 'Food',
    'Character limit of max length 50': 'Character limit of max length 50',
    'Character limit of max length 15': 'Character limit of max length 15',
    'Character limit of max length 60': 'Character limit of max length 60',
    'Character limit of max length 20': 'Character limit of max length 20',
    'Character limit of max length 140': 'Character limit of max length 140',
    Currency: 'Currency',
    Delivery: 'Delivery',
    'Currency Code': 'Currency Code',
    'Delivery Rate': 'Delivery Rate',
    'Currency Symbol': 'Currency Symbol',
    enterYourDetailsBelow: 'Enter your details below',
    LogintoEnatega: 'Login to Ayesay',
    RememberMe: 'Remember me',
    ForgotYourPassword: 'Forgot your password?',
    errorWhileSaving: 'An error occured while saving,Try again',
    Saved: 'Saved',
    Addons: 'Addons',
    AddRemoveAddon: 'Add or Remove Addon',
    MinQuantity: 'Min quantity',
    MinimumQuantity: 'Minimum Quantity',
    MaxQuantity: 'Max quantity',
    MaximumQuantity: 'Maximum quantity',
    Options: 'Options',
    NewOption: 'New Option',
    CategoryUpdatedSuccessfully: 'Category updated successfully',
    CategoryAddedSuccessfully: 'Category added successfully',
    ActionFailedTryAgain: 'Action failed. Please Try again',
    PHCategory: 'Category i.e Breakfast',
    OrderAcceptType: 'Order Accept Type',
    OrderAheadTimeInHours: 'Order Ahead Time in Hours',
    ChooseCurrency: 'Choose Currency',
    ChooseSymbol: 'Choose Symbol',
    PHMail: 'e.g something@email.com',
    EmailName: 'Email Name',
    PHEnatega: 'e.g Ayesay',
    PHFood: 'e.g FOOD',
    ClientSecretKey: 'Client Secret Key',
    Stripe: 'Stripe',
    PublishKey: 'Publish Key',
    AppVersion: 'App Version',
    AndroidMinVersion: 'Android Minimum Version',
    iOSMinVersion: 'iOS Minimum Build Version',
    androidMinVersion: 'Android Minimum Build Version',
    CouponUpdated: 'Coupon updated',
    CouponAdded: 'Coupon Added',
    CuisineUpdated: 'Cuisine updated',
    CuisineAdded: 'Cuisine Added',
    BannerUpdated: 'Banner updated',
    BannerAdded: 'Banner Added',
    Code: 'Code',
    PHCode: 'Code e.g SALE50',
    PHDiscount: 'Discount % i.e 1-99',
    FoodUpdatedSuccessfully: 'Food updated successfully',
    FoodAddedSuccessfully: 'Food added successfully',
    SelectCategory: 'Select Category',
    UploadAnImage: 'Upload an image',
    UniqueTitle: 'Title (must be unique)',
    Discounted: 'Discounted',
    LoadingDots: 'Loading ...',
    ErrorDots: 'Error ...',
    NewAddon: 'New Addon',
    EnategaMultivendor: 'Ayesay',
    ResetPassword: 'Reset Password',
    EnategaDashboard: 'Ayesay Dashboard',
    UpdateOption: 'Update Option',
    AddOption: 'Add Option',
    OrderStatusUpdated: 'Order status updated',
    SpecialInstructions: 'Special Instructions',
    Charges: 'Charges',
    Subtotal: 'Subtotal',
    DeliveryFee: 'Delivery Fee',
    DeliveryRate: 'Delivery Rate',
    MinDeliveryFee: 'Min Delivery Fee',
    MaxDeliveryFee: 'Max Delivery Fee',
    OnDemandDeliveryRate: 'On Demand Delivery Rate',
    OnDemandMinDeliveryFee: 'On Demand Min Delivery Fee',
    OnDemandMaxDeliveryFee: 'On Demand Max Delivery Fee',
    TaxCharges: 'Tax Charges',
    Tip: 'Tip',
    Total: 'Total',
    PaymentMethod: 'Payment Method',
    PaidAmount: 'PaidAmount',
    Accepted: 'Accepted',
    Accept: 'Accept',
    Cancelled: 'Cancelled',
    Cancel: 'Cancel',
    PHReasonIfRejected: 'Reason if Rejected',
    Datetime: 'Datetime',
    CompanyName: 'Company Name',
    PasswordChangedSuccessfully: 'Password Changed successfully',
    ConfirmPassword: 'Confirm Password',
    Reset: 'Reset',
    RestaurantAdded: 'Restaurant added',
    NetworkError: 'Network error',
    FieldsRequired: 'Fields Required',
    Username: 'Username',
    RestaurantUsername: "Restaurant's username",
    RestaurantPassword: "Restaurant's password",
    RestaurantName: "Restaurant's name",
    RestaurantAddress: "Restaurant's address",
    DeliveryTime: 'Delivery Time',
    MinOrder: 'Min Order',
    SalesTax: 'Sales Tax',
    RiderUpdatedSuccessfully: 'Rider updated successfully',
    RiderAddedSuccessfully: 'Rider added successfully',
    EditRider: 'Edit Rider',
    UsernameCannotBeBlank: "Username can't be blank",
    UsernameCannotContainSpaces: "Username can't contain spaces",
    AddRider: 'Add Rider',
    Available: 'Available',
    PHRiderName: 'Rider name',
    Number: 'Number',
    PhoneNumber: 'Phone Number',
    RiderZone: 'Rider Zone',
    SectionUpdatedSuccessfully: 'Section updated successfully',
    SectionAddedSuccessfully: 'Section added successfully',
    EditSection: 'Edit Section',
    AddSection: 'Add Section',
    SectionName: 'Section Name',
    Update: 'Update',
    GENERAL: 'GENERAL',
    MANAGEMENT: 'MANAGEMENT',
    RESTAURANT: 'RESTAURANT',
    BackToAdmin: 'Back to Admin',
    Search: 'Search',
    TippingUpdated: 'Tipping updated',
    EditTipping: 'Edit Tipping',
    AddTipping: 'Add Tipping',
    Tip1: 'Tip 1',
    Tip2: 'Tip 2',
    Tip3: 'Tip 3',
    PHTip1: 'Tip 1 e.g 10',
    PHTip2: 'Tip 2 e.g 20',
    PHTip3: 'Tip 3 e.g 30',
    EditVendor: 'Edit Vendor',
    AddVendor: 'Add Vendor',
    PHNumber: 'e.g 123',
    ZoneUpdatedSuccessfully: 'Zone updated successfully',
    ZoneAddedSuccessfully: 'Zone added successfully',
    SetZoneOnMap: 'Set Zone on Map',
    EditZone: 'Edit Zone',
    AddZone: 'Add Zone',
    Minimum: 'Minimum',
    Maximum: 'Maximum',
    Action: 'Action',
    AvailableAfterPurchasing:
      'This feature will available after purchasing product',
    CommissionRates: 'Commission Rates',
    PHCommission: 'Commission percent',
    Discount: 'Discount',
    Coupons: 'Coupons',
    Cuisines: 'Cuisines',
    Banners: 'Banners',
    StartDate: 'Start Date',
    GraphFilter: 'Graph Filter',
    EndDate: 'End Date',
    Apply: 'Apply',
    UpdatingLocationError:
      'An error occurred while updating location and bounds',
    LocationMarkerRequired: 'Location marker is required',
    DeliveryAreaRequired: 'Delivery area is required',
    LocationUpdatedSuccessfully: 'Location and bounds updated successfully',
    SetLocation: 'Set Location',
    DrawDeliveryBounds: 'Draw Delivery Bounds',
    SetRestaurantLocation: 'Set Restaurant Location',
    RemoveDeliveryBounds: 'Remove Delivery Bounds',
    RemoveRestaurantLocation: 'Remove Restaurant Location',
    Reject: 'Reject',
    Delivered: 'Delivered',
    OrderInformation: 'Order Information',
    RestaurantCol: 'Restaurant',
    Rider: 'Rider',
    OrderTime: 'Order time',
    Dispatch: 'Dispatch',
    PasswordUpdated: 'Password has been updated',
    ForgotPassword: 'Forgot Password',
    NotificationSentAuccessfully: 'Notification sent successfully',
    Notifications: 'Notifications',
    Body: 'Body',
    Zones: 'Zones',
    RequestID: 'Request ID',
    Amount: 'Amount',
    Date: 'Date',
    REQUESTED: 'REQUESTED',
    TRANSFERRED: 'TRANSFERRED',
    CANCELLEDStatus: 'CANCELLED',
    WithdrawRequests: 'Withdraw Requests',
    TotalRestaurants: 'Total Restaurants',
    Restaurants: 'Restaurants',
    Vendors: 'Vendors',
    RestaurantUpdatedSuccessfully: 'Restaurant updated successfully',
    UpdateProfile: 'Update Profile',
    PHRestaurantPassword: "Restaurant's password",
    PHRestaurantName: "Restaurant's name",
    PHRestaurantAddress: "Restaurant's address",
    OrderPrefix: 'Order Prefix',
    Days: 'Days',
    OpenTimes: 'Open Times',
    OrdersPickupTimes: 'Order Pickup Times',
    PreOrderTimeSlots: 'PreOrderTimeSlots',
    TimeSavedSuccessfully: 'Time saved successfully',
    ErrorWhileSavingTime: 'Error while saving time',
    SavingDots: 'Saving...',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    MainPageText: 'A cross-plalform software',
    MainPageText1:
      'A full fledged solution highly suitable to build any restaurant.',
    ViewSite: 'View Site',
    TotalUsers: 'Total Users',
    TotalVendors: 'Total Vendors',
    TotalRiders: 'Total Riders',
    RestaurantSections: 'Restaurant Sections',
    ImageMenu: 'image menu',
    Vendor: 'Vendor',
    AddNewRestaurant: 'Add New Restaurant',
    Ratings: 'Ratings',
    StripeDetailsAttached: 'Stripe Details Attached',
    EditStripeDetails: 'Edit Stripe details',
    SubmitStripeDetails: 'Submit Stripe details',
    'Restaurant Sections': 'Restaurant Sections',
    Riders: 'Riders',
    Tipping: 'Tipping',
    Zone: 'Zone',
    'Commission Rates': 'Commission Rates',
    'Withdraw Requests': 'Withdraw Requests',
    Home: 'Home',
    Profile: 'Profile',
    Option: 'Option',
    Timings: 'Timings',
    Location: 'Location',
    'Back to Admin': 'Back to Admin',
    TotalOrders: 'Total Orders',
    TotalSales: 'Total Sales',
    ClosedAllDay: 'Closed All Day',
    AddRestaurant: 'Add Restaurant',
    EditCoupon: 'Edit Coupon',
    AddCoupon: 'Add Coupon',
    EditCuisine: 'Edit Cuisine',
    AddCuisine: 'Add Cuisine',
    EditBanner: 'Edit Banner',
    AddBanner: 'Add Banner',
    SalesAmount: 'Sales Amount',
    OrderCount: 'Order Count',
    Screen: 'Screen name',
    MON: 'MON',
    TUE: 'TUE',
    WED: 'WED',
    THU: 'THU',
    FRI: 'FRI',
    SAT: 'SAT',
    SUN: 'SUN',
    Open: 'Open',
    ACCEPTED: 'ACCEPTED',
    PENDING: 'PENDING',
    PICKED: 'PICKED',
    ASSIGNED: 'ASSIGNED',
    DELIVERED: 'DELIVERED',
    REJECTED: 'REJECTED',
    shopType: 'Shop Type',
    UploadaLogo: 'Upload a logo',

    StatusUpdated: 'Status Updated!',
    "Status updated to {{status}}": "Status updated to {{status}}",
    "Failed to update status!": "Failed to update status!",
    UpdateSuccessful: "Update Successful",
    UpdateError: "Update Error",
    "The value Should not be in the negative": "The value should not be negative",

    CostType: 'Cost Type',
    PerKM: 'Per KM',
    FixedCost: 'Fixed Cost',
    DeliveryTimeSlot: 'Delivery Timeslot',
    ScheduledPickupTime: 'Pickup Time',
    PickupTime: 'Pickup Time',
    CustomerAddress: 'Customer Address',
    CustomerName: 'Customer Name',
    DestinationAddress: 'Desination Address',
    DeliveryInfo: 'Delivery Info',
    SellerPhoneNumber: 'Seller Phone Number'
  }
}

export default en
