import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useMutation, gql } from '@apollo/client';
import { validateFunc } from '../../../constraints/constraints';
import { saveDeliveryRateConfiguration } from '../../../apollo';
import { Box, Typography, Input, Button, Alert, Select, MenuItem } from '@mui/material';
import useStyles from '../styles';
import useGlobalStyles from '../../../utils/globalStyles';

const SAVE_DELIVERY_RATE_CONFIGURATION = gql`
  ${saveDeliveryRateConfiguration}
`;

function Currency(props) {
  const { t } = props;
  const [deliveryRate, setDeliveryRate] = useState(props.deliveryRate || 0);
  const [minDeliveryFee, setMinDeliveryFee] = useState(props.minDeliveryFee || 0);
  const [maxDeliveryFee, setMaxDeliveryFee] = useState(props.maxDeliveryFee || 0);
  const [onDemandDeliveryRate, setOnDemandDeliveryRate] = useState(props.onDemandDeliveryRate || 0);
  const [onDemandMinDeliveryFee, setOnDemandMinDeliveryFee] = useState(props.onDemandMinDeliveryFee || 0);
  const [onDemandMaxDeliveryFee, setOnDemandMaxDeliveryFee] = useState(props.onDemandMaxDeliveryFee || 0);
  console.log(minDeliveryFee, maxDeliveryFee, onDemandDeliveryRate, onDemandMinDeliveryFee, onDemandMaxDeliveryFee);

  const [costType, setCostType] = useState(props?.costType || 'perKM');
  const [deliveryRateError, setDeliveryRateError] = useState(null);
  const [minDeliveryFeeError, setMinDeliveryFeeError] = useState(null);
  const [maxDeliveryFeeError, setMaxDeliveryFeeError] = useState(null);
  const [onDemandDeliveryRateError, setOnDemandDeliveryRateError] = useState(null);
  const [onDemandMinDeliveryFeeError, setOnDemandMinDeliveryFeeError] = useState(null);
  const [onDemandMaxDeliveryFeeError, setOnDemandMaxDeliveryFeeError] = useState(null);
  const [mutate, { loading }] = useMutation(SAVE_DELIVERY_RATE_CONFIGURATION);

  const validateInput = () => {
    const deliveryRateErrors = !validateFunc({ deliveryRate: deliveryRate }, 'deliveryRate');
    const minDeliveryFeeErrors = !validateFunc({ deliveryRate: minDeliveryFee }, 'deliveryRate');
    const maxDeliveryFeeErrors = !validateFunc({ deliveryRate: maxDeliveryFee }, 'deliveryRate');
    const onDemandDeliveryRateErrors = !validateFunc({ deliveryRate: onDemandDeliveryRate }, 'deliveryRate');
    const onDemandMinDeliveryFeeErrors = !validateFunc({ deliveryRate: onDemandMinDeliveryFee }, 'deliveryRate');
    const onDemandMaxDeliveryFeeErrors = !validateFunc({ deliveryRate: onDemandMaxDeliveryFee }, 'deliveryRate');
    setDeliveryRateError(deliveryRateErrors);
    setMinDeliveryFeeError(minDeliveryFeeErrors);
    setMaxDeliveryFeeError(maxDeliveryFeeErrors);
    setOnDemandDeliveryRateError(onDemandDeliveryRateErrors);
    setOnDemandMinDeliveryFeeError(onDemandMinDeliveryFeeErrors);
    setOnDemandMaxDeliveryFeeError(onDemandMaxDeliveryFeeErrors);
    return deliveryRateErrors;
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [successMessage, setSuccessMessage] = useState('');
  const handleSuccess = (message) => {
    setSuccessMessage(message);
  };

  const handleCostTypeChange = (event) => {
    setCostType(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccessMessage('');
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [successMessage, setSuccessMessage]);

  const [errorMessage, setErrorMessage] = useState('');
  const handleError = (error) => {
    setErrorMessage('An error occurred while saving configuration.');
    console.error('Mutation error:', error);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setErrorMessage('');
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [errorMessage, setErrorMessage]);

  return (
    <Box container className={classes.container}>
      <Box className={classes.flexRow}>
        <Box item className={classes.heading}>
          <Typography variant="h6" className={classes.text}>
            {t('Delivery Rate')}
          </Typography>
        </Box>
      </Box>

      <Box className={classes.form}>
        <form>

        <Box>
            <Typography className={classes.labelText}>
              {t('CostType')}
            </Typography>
            <Select
              style={{ marginTop: -1 }}
              id="input-cost-type"
              name="input-cost-type"
              value={costType}
              onChange={handleCostTypeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className={[
                globalClasses.input,
                deliveryRateError === false
                  ? globalClasses.inputError
                  : deliveryRateError === true
                    ? globalClasses.inputSuccess
                    : '',
              ]}
            >
              <MenuItem style={{ color: 'black' }} value="perKM">{t('PerKM')}</MenuItem>
              <MenuItem style={{ color: 'black' }} value="fixed">{t('FixedCost')}</MenuItem>
            </Select>
          </Box>

          <Box className={globalClasses.flexRow}>
            <Box>
              <Typography className={classes.labelText}>
                {t('DeliveryRate')}
              </Typography>
              <Input
                style={{ marginTop: -1 }}
                // disabled
                id="input-delivery"
                name="input-delivery"
                placeholder={t('Delivery Rate')}
                type="text"
                value={deliveryRate}
                onChange={(e) => {
                  setDeliveryRate(e.target.value);
                }}
                disableUnderline
                className={[
                  globalClasses.input,
                  deliveryRateError === false
                    ? globalClasses.inputError
                    : deliveryRateError === true
                      ? globalClasses.inputSuccess
                      : '',
                ]}
              />
            </Box>

            <Box>
              <Typography className={classes.labelText}>
                {t('OnDemandDeliveryRate')}
              </Typography>
              <Input
                style={{ marginTop: -1 }}
                // disabled
                id="input-ondemand-delivery"
                name="input-ondemand-delivery"
                placeholder={t('On Demand Delivery Rate')}
                type="text"
                value={onDemandDeliveryRate}
                onChange={(e) => {
                  setOnDemandDeliveryRate(e.target.value);
                }}
                disableUnderline
                className={[
                  globalClasses.input,
                  onDemandDeliveryRateError === false
                    ? globalClasses.inputError
                    : onDemandDeliveryRateError === true
                      ? globalClasses.inputSuccess
                      : '',
                ]}
              />
            </Box>
          </Box>

          <Box className={globalClasses.flexRow}>
            <Box>
              <Typography className={classes.labelText}>
                {t('MinDeliveryFee')}
              </Typography>
              <Input
                style={{ marginTop: -1 }}
                // disabled
                id="input-min-delivery"
                name="input-min-delivery"
                placeholder={t('Min Delivery Fee')}
                type="text"
                value={minDeliveryFee}
                onChange={(e) => {
                  setMinDeliveryFee(e.target.value);
                }}
                disableUnderline
                className={[
                  globalClasses.input,
                  minDeliveryFeeError === false
                    ? globalClasses.inputError
                    : minDeliveryFeeError === true
                      ? globalClasses.inputSuccess
                      : '',
                ]}
              />
            </Box>
            <Box>
              <Typography className={classes.labelText}>
                {t('OnDemandMinDeliveryFee')}
              </Typography>
              <Input
                style={{ marginTop: -1 }}
                // disabled
                id="input-min-ondemand-delivery"
                name="input-min-ondemand-delivery"
                placeholder={t('Min On Demand Delivery Fee')}
                type="text"
                value={onDemandMinDeliveryFee}
                onChange={(e) => {
                  setOnDemandMinDeliveryFee(e.target.value);
                }}
                disableUnderline
                className={[
                  globalClasses.input,
                  onDemandMinDeliveryFeeError === false
                    ? globalClasses.inputError
                    : onDemandMinDeliveryFeeError === true
                      ? globalClasses.inputSuccess
                      : '',
                ]}
              />
            </Box>
          </Box>

          <Box className={globalClasses.flexRow}>
            <Box>
              <Typography className={classes.labelText}>
                {t('MaxDeliveryFee')}
              </Typography>
              <Input
                style={{ marginTop: -1 }}
                // disabled
                id="input-max-delivery"
                name="input-max-delivery"
                placeholder={t('Max Delivery Rate')}
                type="text"
                value={maxDeliveryFee}
                onChange={(e) => {
                  setMaxDeliveryFee(e.target.value);
                }}
                disableUnderline
                className={[
                  globalClasses.input,
                  maxDeliveryFeeError === false
                    ? globalClasses.inputError
                    : maxDeliveryFeeError === true
                      ? globalClasses.inputSuccess
                      : '',
                ]}
              />
            </Box>
            <Box>
              <Typography className={classes.labelText}>
                {t('OnDemandMaxDeliveryFee')}
              </Typography>
              <Input
                style={{ marginTop: -1 }}
                // disabled
                id="input-max-ondemand-delivery"
                name="input-max-ondemand-delivery"
                placeholder={t('Max On Demand Delivery Fee')}
                type="text"
                value={onDemandMaxDeliveryFee}
                onChange={(e) => {
                  setOnDemandMaxDeliveryFee(e.target.value);
                }}
                disableUnderline
                className={[
                  globalClasses.input,
                  onDemandMaxDeliveryFeeError === false
                    ? globalClasses.inputError
                    : onDemandMaxDeliveryFeeError === true
                      ? globalClasses.inputSuccess
                      : '',
                ]}
              />
            </Box>
          </Box>
          

          <Box>
            <Button
              className={globalClasses.button}
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                if (validateInput()) {
                  mutate({
                    variables: {
                      configurationInput: {
                        deliveryRate: Number(deliveryRate),
                        minDeliveryFee: Number(minDeliveryFee),
                        maxDeliveryFee: Number(maxDeliveryFee),
                        onDemandDeliveryRate: Number(onDemandDeliveryRate),
                        onDemandMinDeliveryFee: Number(onDemandMinDeliveryFee),
                        onDemandMaxDeliveryFee: Number(onDemandMaxDeliveryFee),
                        costType: costType,
                      },
                    },
                    onCompleted: (data) => {
                      handleSuccess('Configuration saved successfully!');
                    },
                    onError: (error) => {
                      handleError(error);
                    },
                  });
                }
              }}
            >
              {t('Save')}
            </Button>
          </Box>

          <Box mt={2}>
            {successMessage && (
              <Alert className={globalClasses.alertSuccess} variant="filled" severity="success">
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert className={globalClasses.alertError} variant="filled" severity="error">
                {errorMessage}
              </Alert>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default withTranslation()(Currency);