/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'
import { withTranslation, useTranslation } from 'react-i18next'
// core components
import Header from '../components/Headers/Header'
import { getRestaurantProfile, updateTimings, updateRestaurantScoreAndTiming } from '../apollo'
import TimeRangePicker from '@wojtekmaj/react-timerange-picker'
import CustomLoader from '../components/Loader/CustomLoader'
import useGlobalStyles from '../utils/globalStyles'
import { Container, Grid, Box, Button, Alert, useTheme, Input, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { ORDER_ACCEPT_TYPE } from '../utils/enums'
import Dropdown from '../components/Dropdown'
// import TimePicker from 'react-time-picker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs'

const GET_RESTAURANT_PROFILE = gql`
  ${getRestaurantProfile}
`
const UPDATE_TIMINGS = gql`
  ${updateTimings}
`
const UPDATE_RESTAURANT_SCORE = gql`
  ${updateRestaurantScoreAndTiming}
`

const transformTimings = (daysTimings) => {
  return Object.keys(daysTimings).map(v => {
    return {
      day: v,
      times: daysTimings[v].map(timings => ({
        startTime: timings[0].split(':'),
        endTime: timings[1].split(':')
      }))
    }
  })
}


const Timings = props => {
  const [openTimes, setOpenTimes] = useState({})
  const [ordersPickupTimes, setOrdersPickupTimes] = useState({})
  const [preOrderTimeSlots, setPreOrderTimeSlots] = useState({})
  const restaurantId = localStorage.getItem('restaurantId')
  const days = ['MON','TUE','WED','THU','FRI','SAT','SUN']

  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [orderAcceptType, setOrderAcceptType] = useState('')
  const [orderAheadTimeInHours, setOrderAheadTimeInHours] = useState()
  const { t } = props
  const [mutate, { loading }] = useMutation(UPDATE_TIMINGS)
  const [mutateScore] = useMutation(UPDATE_RESTAURANT_SCORE)
  const transformedTimes = {}
  const transformedPickupTimes = {}
  const transformedPreorderTimes = {}

  const onChangeTime = (day, values) => {
    openTimes[day] = values
    setOpenTimes(openTimes)
  }

  const onChangePreorderTimeSlots = (day, values) => {
    preOrderTimeSlots[day] = values
    setPreOrderTimeSlots(preOrderTimeSlots)
  }

  const onChangePickupTimes = (day, values) => {
    ordersPickupTimes[day] = values
    setOrdersPickupTimes(ordersPickupTimes)
  }

  const { data, error: errorQuery, loading: loadingQuery } = useQuery(
    GET_RESTAURANT_PROFILE,
    {
      variables: { id: restaurantId }
    }
  )

  data && data.restaurant.openingTimes.forEach(value => {
    transformedTimes[value.day] = value.times.map(t => [
      `${t.startTime[0]}:${t.startTime[1]}`,
      `${t.endTime[0]}:${t.endTime[1]}`
    ])
  })

  data && data.restaurant.ordersPickupTimes.forEach(value => {
    transformedPickupTimes[value.day] = value.times.map(t => [
      `${t.startTime[0]}:${t.startTime[1]}`,
      `${t.endTime[0]}:${t.endTime[1]}`
    ])
  })

  data && data.restaurant.preOrderTimeSlots.forEach(value => {
    transformedPreorderTimes[value.day] = value.times.map(t => [
      `${t.startTime[0]}:${t.startTime[1]}`,
      `${t.endTime[0]}:${t.endTime[1]}`
    ])
  })

  useEffect(() => {
    data && setOrderAcceptType(data.restaurant?.orderAcceptType)
    data && setOrderAheadTimeInHours(data.restaurant?.orderAheadTimeInHours)
  }, [data])
  
  
  const globalClasses = useGlobalStyles()

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className={globalClasses.flex} fluid>
        {errorQuery ? <span>Error! {errorQuery.message}</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <>
          <Typography>{t('Order Acceptance Type')}</Typography>
          <Dropdown
            // title={t('Order Acceptance Type')}
            values={Object.values(ORDER_ACCEPT_TYPE)}
            defaultValue={data?.restaurant?.orderAcceptType || ORDER_ACCEPT_TYPE.PREORDER}
            id={'order-accept-type'}
            name={'orderAcceptType'}
            displayEmpty={true}
            onChange={(e) => setOrderAcceptType(e.target.value)}
          />
          <br />
          { orderAcceptType==='preorder' && (
            <>
          <Typography>{t('OrderAheadTimeInHours')}</Typography>
          <Input
              id="input-order-ahead-time"
              name="input-order-ahead-time"
              placeholder={'24'}
              type="number"
              value={orderAheadTimeInHours}
              onChange={e => {
                setOrderAheadTimeInHours(Number(e.target.value))
              }}
              disableUnderline
              className={globalClasses.input}
            />
            </>
          )}
          <Box className={globalClasses.timing}>
            {
              orderAcceptType==='ondemand' && (
              <>
                <Grid container className={globalClasses.timingHeader}>
                  <Grid item md={2} lg={2}>
                    {t('Days')}
                  </Grid>
                  <Grid item md={7} lg={7}>
                    {t('OpenTimes')}
                  </Grid>
                </Grid>
            
                {
                days.map((d) => {
                    return (
                      <DayComponent
                        key={'open-times-' + t(d)}
                        day={t(d)}
                        value={transformedTimes[d] || [['00:00', '23:59']]}
                        onChangeTime={onChangeTime}
                      />
                    )
                  })
                }
              </>
            )}
            {
              orderAcceptType==='preorder' && (
              <>
                <Grid container className={globalClasses.timingHeader}>
                  <Grid item md={1} lg={1}>
                    {t('Days')}
                  </Grid>
                  <Grid item md={3} lg={3}>
                    {t('OrdersPickupTimes')}
                  </Grid>
                  <Grid item md={5} lg={5}>
                    {t('PreOrderTimeSlots')}
                  </Grid>
                </Grid>
            
                {
                days.map((d) => {
                    return (
                      <PreOrderDayComponent
                        key={'preorder-time-' + t(d)}
                        day={t(d)}
                        pickupTimeValues={transformedPickupTimes[d] || []}
                        preorderTimeValues={transformedPreorderTimes[d] || []}
                        onChangePickupTime={onChangePickupTimes}
                        onChangePreorderTime={onChangePreorderTimeSlots}
                      />
                    )
                  })
                }
              </>
            )}
            <Button
              onClick={e => {
                e.preventDefault()
                mutate({
                  variables: {
                    timings: {
                      restaurantId,
                      orderAcceptType,
                      orderAheadTimeInHours,
                      openingTimes: transformTimings(openTimes),
                      ordersPickupTimes: transformTimings(ordersPickupTimes),
                      preOrderTimeSlots: transformTimings(preOrderTimeSlots)
                    },
                    orderAcceptType
                  },
                  onCompleted: () => {
                    setSuccessMessage(t('TimeSavedSuccessfully'))
                    setTimeout(() => setSuccessMessage(''), 5000)
                    setErrorMessage('')
                    
                    mutateScore({
                      variables: {
                        id: restaurantId
                      }
                    })
                  },
                  onError: error => {
                    setErrorMessage(t('ErrorWhileSavingTime'))
                    setTimeout(() => setErrorMessage(''), 5000)
                    setSuccessMessage('')
                  }
                })
              }}
              className={[globalClasses.button, globalClasses.mb]}>
              {loading ? t('SavingDots') : t('Save')}
            </Button>
            {successMessage && (
              <Alert
                className={globalClasses.alertSuccess}
                variant="filled"
                severity="success">
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert
                className={globalClasses.alertError}
                variant="filled"
                severity="error">
                {errorMessage}
              </Alert>
            )}
          </Box>
          </>
        )}
      </Container>
    </>
  )
}

export default withTranslation()(Timings)
const PreOrderDayComponent = ({ day, pickupTimeValues, preorderTimeValues, onChangePickupTime, onChangePreorderTime }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  useEffect(() => {
    onChangePickupTime(day, pickupTimes)
    onChangePreorderTime(day, preorderTimes)
  })
  const globalClasses = useGlobalStyles()

  const [pickupTimes, setPickupTimes] = useState(pickupTimeValues)
  const [preorderTimes, setPreorderTimes] = useState(preorderTimeValues)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid container className={globalClasses.dayComponent}>
      <Grid item lg={1} md={1} className={globalClasses.day}>
        {day}
      </Grid>
      <Grid item lg={3} md={3}>
        {pickupTimes.map((time, index) => (
          <Box key={time + index}>
            {/* <TimePicker 
              required
              disableClock
              format="hh:mm a"
              clearIcon={null}
              value={time}
              onChange={(value => {
                console.log('clicked ' + value)
                pickupTimes[index] = value
                setPickupTimes([...pickupTimes])
              })}
            /> */}
            <TimePicker
              disableOpenPicker
              format="hh:mm a"
              value={dayjs(time[0], 'HH:mm')}
              sx={{
                '& .MuiInputBase-input': {
                  color: 'black', // Text color
                  // height: 10,
                  height: '4px', // Set the height of the TextField  
                },
                '& .MuiInputBase-root': {
                  height: '100%', // Ensure the input element also respects the height
                },
              }}
              onChange={(value => {
                const startTime = dayjs(value).format('HH:mm')
                pickupTimes[index] = [startTime, startTime]
                setPickupTimes([...pickupTimes])
              })}
            />
          </Box>
        ))}
        {pickupTimes.length === 0 && <span>{t('ClosedAllDay')}</span>}
      </Grid>
      <Grid item lg={5} md={5}>
        {preorderTimes.map((time, index) => (
          <Box key={time + index}>
            <TimeRangePicker
              required
              rangeDivider="to"
              disableClock
              format="hh:mm a"
              clearIcon={null}
              style={{ backgroundColor: 'red', color: 'green' }}
              value={time}
              onChange={value => {
                preorderTimes[index] = value
                setPreorderTimes([...preorderTimes])
              }}
            />
            {index === preorderTimes.length - 1 && (
              <AddIcon
                style={{
                  backgroundColor: theme.palette.warning.dark,
                  color: theme.palette.common.black,
                  borderRadius: '50%',
                  marginBottom: -5,
                  marginLeft: 10
                }}
                onClick={e => {
                  e.preventDefault()
                  setPickupTimes([...pickupTimes, ['19:00', '19:00']])
                  setPreorderTimes([...preorderTimes, ['19:00', '22:00']])
                }}
              />
            )}
            {preorderTimes.length > 1 && (
              <RemoveIcon
                style={{
                  backgroundColor: theme.palette.common.black,
                  color: theme.palette.warning.dark,
                  borderRadius: '50%',
                  marginLeft: 10,
                  marginRight: 10,
                  marginBottom: -5
                }}
                onClick={e => {
                  e.preventDefault()
                  pickupTimes.splice(index, 1)
                  preorderTimes.splice(index, 1)
                  setPickupTimes([...pickupTimes])
                  setPreorderTimes([...preorderTimes])
                }}
              />
            )}
          </Box>
        ))}
        {preorderTimes.length === 0 && <span>{t('ClosedAllDay')}</span>}
      </Grid>
      <Grid item lg={3} md={3}>
        {preorderTimes.length > 0 ? (
          <Button
            onClick={e => {
              e.preventDefault()
              setPickupTimes([])
              setPreorderTimes([])
            }}
            className={globalClasses.closeBtn}>
            {t('ClosedAllDay')}
          </Button>
        ) : null}
        {preorderTimes.length === 0 ? (
          <Button
            onClick={e => {
              e.preventDefault()
              setPickupTimes([['19:00', '19:00']])
              setPreorderTimes([['19:00', '22:00']])
            }}
            className={globalClasses.openBtn}>
            {t('Open')}
          </Button>
        ) : null}
      </Grid>
    </Grid>
    </LocalizationProvider>
  )
}

const DayComponent = ({ day, value, onChangeTime }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  useEffect(() => {
    onChangeTime(day, values)
  })
  const globalClasses = useGlobalStyles()

  const [values, onChange] = useState(value)
  return (
    <Grid container className={globalClasses.dayComponent}>
      <Grid item lg={2} md={2} className={globalClasses.day}>
        {day}
      </Grid>
      <Grid item lg={7} md={7}>
        {values.map((value, index) => (
          <Box key={value + index}>
            <TimeRangePicker
              required
              rangeDivider="to"
              disableClock
              format="hh:mm a"
              clearIcon={null}
              style={{ backgroundColor: 'red', color: 'green' }}
              value={value}
              onChange={value => {
                values[index] = value
                onChange([...values])
              }}
            />
            {index === values.length - 1 && (
              <AddIcon
                style={{
                  backgroundColor: theme.palette.warning.dark,
                  color: theme.palette.common.black,
                  borderRadius: '50%',
                  marginBottom: -5,
                  marginLeft: 10
                }}
                onClick={e => {
                  e.preventDefault()
                  onChange([...values, ['00:00', '23:59']])
                }}
              />
            )}
            {values.length > 1 && (
              <RemoveIcon
                style={{
                  backgroundColor: theme.palette.common.black,
                  color: theme.palette.warning.dark,
                  borderRadius: '50%',
                  marginLeft: 10,
                  marginRight: 10,
                  marginBottom: -5
                }}
                onClick={e => {
                  e.preventDefault()
                  values.splice(index, 1)
                  onChange([...values])
                }}
              />
            )}
          </Box>
        ))}
        {values.length === 0 && <span>{t('ClosedAllDay')}</span>}
      </Grid>
      <Grid item lg={3} md={3}>
        {values.length > 0 ? (
          <Button
            onClick={e => {
              e.preventDefault()
              onChange([])
            }}
            className={globalClasses.closeBtn}>
            {t('ClosedAllDay')}
          </Button>
        ) : null}
        {values.length === 0 ? (
          <Button
            onClick={e => {
              e.preventDefault()
              onChange([['00:00', '23:59']])
            }}
            className={globalClasses.openBtn}>
            {t('Open')}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  )
}
